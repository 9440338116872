import axios from 'axios';
import { useState, useEffect } from 'react';
import '../App.css';
import Banner from '../components/Banner';
import ResultImageModal from '../components/ResultImageModal';
import Loading from '../components/Loading';
import TitleComponent from '../components/TitleComponent';
import { useSearchParams, useNavigate } from 'react-router-dom';
import LazyImage from '../components/lazyImage';

import Footer from '../components/Footer';

function See() {
    const [searchParams, setSearchParams] = useSearchParams();

    const userId = searchParams.get('userId');
    const secretKey = searchParams.get('secretKey');

    const [isLoading, setIsLoading] = useState(false);
    const [resultImages, setResultImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [modalImageIndex, setModalImageIndex] = useState(0);
    const [renewaled, setRenewaled] = useState(false);

    const olderStyleList = ['유화', '연필/색연필', '파스텔', '컨셉아트'];
    const recentStyleList = [
        '스코틀랜드',
        '호주 골드코스트',
        '스위스',
        '캐나다',
        '프랑스 파리',
        '미국 뉴욕',
        '체코 프라하',
        '이집트',
        '태국',
        '일본',
    ];

    useEffect(() => {
        getResultImages();
    }, [userId, secretKey]);

    const navigate = useNavigate();

    const routeChange = (isInternal, path, e) => {
        e.preventDefault();
        if (isInternal) {
            let newPath = path;
            navigate(newPath);
        } else {
            window.open(path);
        }
    };

    const getResultImages = () => {
        setIsLoading(true);
        let url = `${process.env.REACT_APP_API_ADDRESS}/get_images?userId=${userId}&secretKey=${secretKey}`;

        axios
            .get(url)
            .then((res) => {
                if (
                    res.data.modelVersion === undefined ||
                    res.data.modelVersion === null ||
                    res.data.modelVersion.N === '0'
                ) {
                    setRenewaled(false);
                } else if (res.data.modelVersion.N === '1') {
                    setRenewaled(true);
                } else {
                    setRenewaled(false);
                }

                setResultImages(res.data.urls);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.data.error === 'image is not ready') {
                    alert('이미지가 존재하지 않습니다');
                    navigate(`/status?userId=${userId}&secretKey=${secretKey}`);
                }
                setIsLoading(false);
            });
    };

    const deleteForever = (e) => {
        // confirm deleting

        let confirm = window.confirm(
            '정말로 데이터를 영구 삭제하시겠습니까? 삭제한 데이터는 복구할 수 없습니다.'
        );
        if (confirm) {
            let url = `${process.env.REACT_APP_API_ADDRESS}/delete_information`;
            let data = {
                userId: userId,
                secretKey: secretKey,
            };
            let config = { 'Content-Type': 'application/json' };

            axios
                .post(url, data, config)
                .then((res) => {
                    if (res.data.message) {
                        alert(res.data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            return;
        }
    };

    const changeShowModal = () => {
        setShowModal(!showModal);
    };

    const showOnModal = (image, imageIndex, e) => {
        e.preventDefault();
        changeShowModal();
        setModalImage(image);
        setModalImageIndex(imageIndex);
    };

    const olderImageDiv = (images, styleIndex) => {
        return (
            <>
                <div className="result-images">
                    {images.map((image, index) => {
                        return (
                            <div
                                onClick={(e) => {
                                    showOnModal(
                                        image,
                                        styleIndex * 50 + index, // 200장의 결과 리스트에서의 인덱스
                                        e
                                    );
                                }}
                                key={index}
                            >
                                <LazyImage src={image} />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    const recentImageDiv = (images, styleIndex) => {
        return (
            <>
                <div className="result-images">
                    {images.map((image, index) => {
                        return (
                            <div
                                onClick={(e) => {
                                    showOnModal(
                                        image,
                                        styleIndex * 10 + index, // 200장의 결과 리스트에서의 인덱스
                                        e
                                    );
                                }}
                                key={index}
                            >
                                <LazyImage src={image} />
                            </div>
                        );
                    })}
                </div>
            </>
        );
    };

    const subtitleDiv = () => {
        return (
            <>
                그림이 모두 완성되었습니다. <br />
                마음에 드는 이미지를 저장하거나 공유하세요.
                <br />
                캔버스 액자로 제작하여 실물로 만나보세요.
            </>
        );
    };

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="app ">
                    <div className="border" style={{ maxHeight: 'none' }}>
                        <div className="border-padding">
                            <TitleComponent subtitle={subtitleDiv()} />

                            <div className="page-content result-image-view">
                                {renewaled
                                    ? recentStyleList.map((style, index) => {
                                          return (
                                              <div key={index}>
                                                  <Banner
                                                      bannerTitle={style}
                                                      contentDiv={recentImageDiv(
                                                          resultImages.slice(
                                                              (resultImages.length /
                                                                  recentStyleList.length) *
                                                                  index,
                                                              (resultImages.length /
                                                                  recentStyleList.length) *
                                                                  (index + 1)
                                                          ),
                                                          index
                                                      )}
                                                  />
                                              </div>
                                          );
                                      })
                                    : olderStyleList.map((style, index) => {
                                          return (
                                              <div key={index}>
                                                  <Banner
                                                      bannerTitle={style}
                                                      contentDiv={olderImageDiv(
                                                          resultImages.slice(
                                                              (resultImages.length /
                                                                  olderStyleList.length) *
                                                                  index,
                                                              (resultImages.length /
                                                                  olderStyleList.length) *
                                                                  (index + 1)
                                                          ),
                                                          index
                                                      )}
                                                  />
                                              </div>
                                          );
                                      })}
                            </div>
                        </div>
                    </div>

                    {showModal && modalImage ? (
                        <ResultImageModal
                            setShowModal={setShowModal}
                            modalImageIndex={modalImageIndex}
                            modalImage={modalImage}
                            secretKey={secretKey}
                            userId={userId}
                        />
                    ) : null}

                    <div style={{ marginBottom: '50px' }}>
                        <button
                            onClick={(e) => {
                                deleteForever(e);
                            }}
                        >
                            데이터 영구삭제
                        </button>
                    </div>
                    <Footer />
                </div>
            )}
        </div>
    );
}

export default See;
