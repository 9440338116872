import axios from 'axios';
import '../App.css';
import Banner from '../components/Banner';
import ProgressBar from '../components/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Loading from '../components/Loading';
import TitleComponent from '../components/TitleComponent';
import { useSearchParams } from 'react-router-dom';
import Footer from '../components/Footer';

function Order() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [completed, setCompleted] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const userId = searchParams.get('userId');
    const secretKey = searchParams.get('secretKey');

    const [status, setStatus] = useState({
        cStatus: '',
        name: '',
        createDatetime: '',
        startDatetime: '',
        endDatetime: '',
        kind: '',
        paid: '',
        price: '',
        receipt: '',
    });

    let navigate = useNavigate();

    const routeChange = (isInternal, path, e) => {
        e.preventDefault();
        if (isInternal) {
            let newPath = path;
            navigate(newPath);
        } else {
            window.open(path);
        }
    };

    useEffect(() => {
        getStatus();
    }, []);

    const getStatus = () => {
        setIsLoading(true);
        let url = `${process.env.REACT_APP_API_ADDRESS}/users/${userId}`;

        axios
            .get(url)
            .then((res) => {
                setStatus(res.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error.response.data);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setCompleted(getProgressValue());
    }, [status]);

    const getProgressValue = () => {
        if (status.startDatetime != '') {
            let startDatetime = status.startDatetime;
            // make utc 0 time to korea time

            startDatetime = new Date(startDatetime);
            startDatetime.setHours(startDatetime.getHours() + 9);

            // let eta = startdate time plus 40 mins
            let eta = new Date(startDatetime);
            eta.setMinutes(eta.getMinutes() + 40);

            // get current time
            let now = new Date();

            // get ratio between eta and startdatetime (0~1)
            // make datetime to timestamp
            startDatetime = startDatetime.getTime();
            eta = eta.getTime();
            now = now.getTime();

            let ratio = (now - startDatetime) / (eta - startDatetime);

            if (status.cStatus === '1') {
                // if ratio is bigger than 1, set it to 0.99
                if (ratio > 1) {
                    ratio = 0.99;
                } else if (ratio < 0) {
                    ratio = 0;
                }
            } else if (status.cStatus === '2') {
                ratio = 1.0;
            } else if (status.cStatus === '3') {
                ratio = 0;
            } else if (status.cStatus === '5') {
                ratio = 1;
            }
            // drop decimal point
            return Math.floor(ratio * 100);
        } else {
            return 0;
        }
    };

    const changeDatetimeToString = (datetime) => {
        if (datetime === '') return datetime;

        let date = new Date(datetime);
        date.setHours(date.getHours() + 9);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let min = date.getMinutes();

        let dateString = `${year}년 ${month}월 ${day}일 ${hour}시 ${min}분`;
        return dateString;
    };

    const statusDiv = () => {
        return (
            <>
                <div>
                    <div className="info-title">진행 상태</div>
                    {status.cStatus === '1' ? (
                        <div
                            className="info-title"
                            style={{ margin: '20px 0 0 0 ' }}
                        >
                            그림이 아직 완성되지 않았습니다. 조금만 더
                            기다려주세요.
                        </div>
                    ) : null}

                    {/* todo: cStatus 값에 따른 0~100 사이의 값 추가 */}
                    <ProgressBar bgcolor={'#ff7b07'} completed={completed} />

                    <div className="justify-content-space-between">
                        <div className="info-title">결제 완료 시간</div>
                        <div className="info-title">
                            {changeDatetimeToString(status.createDatetime)}
                        </div>
                    </div>
                    <div className="justify-content-space-between">
                        <div className="info-title">그림 생성 시작 시간</div>
                        <div className="info-title">
                            {status.startDatetime.length
                                ? changeDatetimeToString(status.startDatetime)
                                : '아직 시작되지 않았습니다'}
                        </div>
                    </div>

                    {status.cStatus === '2' ? (
                        <div>
                            <div className="justify-content-space-between">
                                <div className="info-title">
                                    그림 생성 완료 시간
                                </div>
                                <div className="info-title">
                                    {changeDatetimeToString(status.endDatetime)}
                                </div>
                            </div>

                            <div className="right-align-button">
                                <div
                                    className="white-button"
                                    style={{
                                        fontSize: '15px',
                                        marginRight: '0',
                                    }}
                                    onClick={(e) => {
                                        routeChange(
                                            true,
                                            `/see?userId=${userId}&secretKey=${secretKey}`,
                                            e
                                        );
                                    }}
                                >
                                    완성된 그림 보러가기
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </>
        );
    };

    const infoDiv = () => {
        return (
            <>
                <div>
                    <div className="justify-content-space-between">
                        <div className="info-title">대상 이름</div>
                        <div className="info-content">{status.name}</div>
                    </div>
                    <div className="justify-content-space-between">
                        <div className="info-title">대상 종류</div>
                        <div className="info-content">
                            {status.kind.includes(' dog') ||
                            status.kind === 'dog'
                                ? '강아지'
                                : status.kind.includes(' cat') ||
                                  status.kind === 'cat'
                                ? '고양이'
                                : '오류'}
                        </div>
                    </div>
                    <div className="justify-content-space-between">
                        <div className="info-title">결제 여부</div>
                        <div className="info-content">
                            {status.paid ? (
                                <span>결제 완료</span>
                            ) : (
                                <span style={{ color: '#DC4E4E' }}>
                                    결제 미완료
                                </span>
                            )}
                        </div>
                    </div>

                    {status.paid ? (
                        <>
                            <div className="justify-content-space-between">
                                <div className="info-title">결제 금액</div>
                                <div className="info-content">
                                    {status.price}원
                                </div>
                            </div>
                            <div className="justify-content-space-between">
                                <div className="info-title">결제 영수증</div>
                                <div className="info-content">
                                    <div
                                        className="white-button"
                                        style={{
                                            fontSize: '15px',
                                            marginRight: '0',
                                        }}
                                        onClick={(e) => {
                                            routeChange(
                                                false,
                                                status.receipt,
                                                e
                                            );
                                        }}
                                    >
                                        영수증
                                    </div>
                                </div>
                            </div>
                            {status.cStatus === '5' ? (
                                <div style={{ marginTop: '20px' }}>
                                    <div className="justify-content-space-between">
                                        <div className="info-title">
                                            영구삭제된 컬렉션입니다.
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </>
                    ) : null}
                </div>
            </>
        );
    };

    const subtitleDiv = () => {
        return (
            <>
                레이몽을 사용해주셔서 감사합니다
                <br />
                현재 사진을 해석하고 그림을 생성 중 입니다. 진행 상태를
                확인하세요
            </>
        );
    };

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="app ">
                    <div className="border-padding">
                        <TitleComponent subtitle={subtitleDiv()} />

                        <div className="page-content">
                            <Banner
                                bannerTitle={'INFO'}
                                contentDiv={infoDiv()}
                            />
                            <Banner
                                bannerTitle={'STATUS'}
                                contentDiv={statusDiv()}
                            />
                        </div>
                    </div>

                    <Footer />
                </div>
            )}
        </div>
    );
}

export default Order;
