import '../assets/styles/loading.css';

function Loading() {
    return (
        <div className='loading-background'>
            <div className='loading-container'>
                <div className='circle'></div>
                <div className='circle'></div>
                <div className='circle'></div>
                <div className='circle'></div>
                <div className='circle'></div>
            </div>
        </div>
    );
}

export default Loading;
