import axios from 'axios';
import '../App.css';
import Banner from '../components/Banner';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Loading from '../components/Loading';
import check from '../assets/images/payment-success.png';
import TitleComponent from '../components/TitleComponent';
import Footer from '../components/Footer';

import { useNavigate } from 'react-router-dom';

function PaymentSuccess() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentVerified, setPaymentVerified] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const paramPaymentKey = searchParams.get('paymentKey');
    const paramOrderId = searchParams.get('orderId');
    const paramAmount = searchParams.get('amount');

    let navigate = useNavigate();

    const routeChange = (isInternal, path, e) => {
        if (isInternal) {
            let newPath = path;
            navigate(newPath);
        } else {
            window.open(path);
        }
    };

    useEffect(() => {
        let url = `${process.env.REACT_APP_API_ADDRESS}/payment`;
        let data = {
            userId: paramOrderId,
            paymentKey: paramPaymentKey,
            price: paramAmount,
        };

        let config = { 'Content-Type': 'application/json' };

        setIsLoading(true);
        axios
            .post(url, data, config)
            .then((res) => {
                setPaymentVerified(true);
                setIsLoading(false);
                let url = `${process.env.REACT_APP_API_ADDRESS}/exec_banana`;
                let data = {
                    userId: paramOrderId,
                };

                axios
                    .post(url, data, config)
                    .then((res) => {})
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                if (error.response.data.error === 'Already paid') {
                    setPaymentVerified(true);
                    setMessage('이미 결제가 완료되었습니다.');
                }

                if (error.response.data.error === 'Payment is not confirmed') {
                    alert('결제 확인이 완료되지 않았습니다');
                    routeChange(true, '/payment-fail', error);
                }
                setIsLoading(false);
            });
    }, []);

    const paymentStatusDiv = () => {
        return (
            <>
                <div>
                    {paymentVerified ? (
                        <div
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            <div className="payment-success-check">
                                <img src={check} />
                            </div>
                            <div
                                className="orange-info-title"
                                style={{
                                    fontSize: '25px',
                                    marginBottom: '10px',
                                }}
                            >
                                PAYMENT RECEIVED
                            </div>
                            {message ? (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {message}
                                </div>
                            ) : null}
                            <div className="payment-success-body">
                                <div>결제가 성공적으로 완료되었습니다</div>
                                <div>
                                    결과물 완성까지는 약 3
                                    <span style={{ fontFamily: 'sans-serif' }}>
                                        ~
                                    </span>
                                    40분이 소요됩니다
                                </div>
                                <div>
                                    진행 상태를 확인하고 완성된 결과물을 보려면
                                    <br />
                                    결제 전 입력했던 이메일로 전송된 링크를
                                    확인해주세요
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </>
        );
    };

    const subtitleDiv = () => {
        return (
            <>
                결제가 완료되었습니다 <br />
                <strong>
                    이메일로 전송된 진행 상태를 알 수 있는 링크를 확인해주세요
                </strong>
            </>
        );
    };

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="app">
                    <div className="border-padding">
                        <TitleComponent subtitle={subtitleDiv()} />

                        <div className="center-button">
                            <Banner
                                bannerTitle={'PAYMENT SUCCESS'}
                                contentDiv={paymentStatusDiv()}
                            />
                        </div>
                    </div>

                    <Footer />
                </div>
            )}
        </div>
    );
}

export default PaymentSuccess;
