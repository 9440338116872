// import app.css
import '../assets/styles/banner.css';

function Banner(props) {
    const bannerTitle = props.bannerTitle;
    const contentDiv = props.contentDiv;

    return (
        <div className='banner'>
            <div className='banner-title'>
                <p>{bannerTitle}</p>
            </div>
            <div className='banner-content'>{contentDiv}</div>
        </div>
    );
}

export default Banner;
