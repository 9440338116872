import './App.css';
import { useNavigate } from 'react-router-dom';

import mainImage from './assets/images/main-page.png';
import TitleComponent from './components/TitleComponent';
import Footer from './components/Footer';

import dog from './assets/images/mongsoon_nice_tp.png';
import dogGenerated from './assets/images/mongsoon_generated_tp.png';
import ray from './assets/images/ray_collage_tp.png';
import rayGenerated from './assets/images/ray_generated_tp.png';
import arrow from './assets/images/arrow.png';

function App() {
    let navigate = useNavigate();

    const routeChange = (isInternal, path, e) => {
        e.preventDefault();
        if (isInternal) {
            let newPath = path;
            navigate(newPath);
        } else {
            window.open(path);
        }
    };

    return (
        <div className="app">
            <div className="landing-content">
                <TitleComponent
                    style={{ marginRight: '20px' }}
                    subtitle={'AI가 그려주는 동물 아트 한 보따리'}
                />
                <button
                    className="get-started-button"
                    onClick={(e) => {
                        routeChange(true, '/main', e);
                    }}
                >
                    GET STARTED
                </button>

                <div className="landing-image">
                    <img src={mainImage} />
                </div>
            </div>

            <div className="intro-video-section white-background">
                <div className="mid-title">레이몽 소개영상</div>
                <iframe
                    className="landing-video"
                    src="https://www.youtube.com/embed/ZeLntn6x3VI"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </div>

            <div style={{ width: '100%' }}>
                <div className="block">
                    <div
                        style={{ marginBottom: '8px' }}
                        className="colored-bold"
                    >
                        세계 여행을 떠난 나의 반려동물!
                    </div>
                    <div className="light">
                        스코틀랜드, 호주, 스위스 등 총 10개의 나라의 멋진 풍경과
                        랜드마크에 있는 <br />
                        나의 반려동물을 레이몽의 그림을 통해 만나보세요
                        <br /> 레이몽 AI가 5~10장의 동물의 사진을 통해
                        반려동물의 모습을 자세히 <br />
                        관찰하여 이해하고 다양한 모습으로 그려냅니다
                        <br />
                    </div>

                    <div className="light"></div>

                    <div className="bold"></div>
                </div>

                <div className="mid-title">
                    아래와 같은 그림이 나올 수 있어요
                </div>
                <div className="image-examples">
                    <div className="example">
                        <img
                            onMouseEnter={(e) => {
                                // change image
                                e.target.src = dog;
                            }}
                            onMouseLeave={(e) => {
                                // change image
                                e.target.src = dogGenerated;
                            }}
                            onTouchStart={(e) => {
                                // change image
                                e.target.src =
                                    e.target.src == dogGenerated
                                        ? dog
                                        : dogGenerated;
                            }}
                            src={dogGenerated}
                        />
                    </div>
                    <div className="example">
                        <img
                            onMouseEnter={(e) => {
                                // change image
                                e.target.src = ray;
                            }}
                            onMouseLeave={(e) => {
                                // change image
                                e.target.src = rayGenerated;
                            }}
                            onTouchStart={(e) => {
                                // change image
                                e.target.src =
                                    e.target.src == rayGenerated
                                        ? ray
                                        : rayGenerated;
                            }}
                            src={rayGenerated}
                        />
                    </div>
                </div>
                <div className="example-explain">사진을 터치해보세요!</div>
            </div>

            <div className="white-background">
                <div className="block">
                    <div className="mid-title">
                        리뉴얼 기념 아트 100장 한 보따리
                    </div>
                    <div className="mid-title">
                        <span
                            style={{
                                textDecoration: 'line-through',
                                color: '#aaaaaa',
                            }}
                        >
                            6900원
                        </span>
                        <img
                            style={{
                                width: '30px',
                                height: '20px',
                                margin: '8px 15px',
                            }}
                            src={arrow}
                        />
                        <span style={{ color: '#ff7b07', fontWeight: 'bold' }}>
                            3900원
                        </span>
                    </div>

                    <div className="center-button">
                        <div className="price-instruction">
                            시간은 약 40분 ~ 1시간 가량 소요됩니다
                        </div>
                        <button
                            className="get-started-button main-bottom-button"
                            onClick={(e) => {
                                routeChange(true, '/main', e);
                            }}
                        >
                            GET STARTED
                        </button>
                    </div>
                </div>
            </div>

            <div
                className="block"
                style={{ textAlign: 'center', fontFamily: 'Suite-light' }}
            >
                레이몽은 데이터 보호를 위해 최선을 다하고 있으며 반려동물의
                사진을 유출하거나 추가 학습 자료로 사용하지 않습니다
            </div>

            <Footer />
        </div>
    );
}

export default App;
