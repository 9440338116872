import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../App.css';
import Banner from '../components/Banner';
import fail from '../assets/images/payment-fail.png';
import TitleComponent from '../components/TitleComponent';
import Footer from '../components/Footer';

function PaymentFail() {
    const [searchParams, setSearchParams] = useSearchParams();

    const paymentFailDiv = () => {
        return (
            <>
                <div>
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <div className="payment-success-check">
                            <img src={fail} />
                        </div>
                        <div
                            className="info-title"
                            style={{
                                fontSize: '25px',
                                marginBottom: '10px',
                                color: '#DC4E4E',
                            }}
                        >
                            SOMETHING WENT WRONG
                        </div>
                        <div className="payment-success-body">
                            {searchParams.get('code') ===
                            'PAY_PROCESS_CANCELED' ? (
                                <div>결제가 취소되었습니다</div>
                            ) : searchParams.get('code') ===
                              'PAY_PROCESS_ABORTED' ? (
                                <div>
                                    결제 진행 중 승인에 실패하여 결제가
                                    중단되었습니다
                                </div>
                            ) : searchParams.get('code') ===
                              'REJECT_CARD_COMPANY' ? (
                                <div>카드사에서 결제 승인이 거절되었습니다</div>
                            ) : (
                                <div>
                                    알 수 없는 이유로 결제가 실패하였습니다
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const subtitleDiv = () => {
        return (
            <>
                결제 완료가 되지 않았습니다 <br />
                <strong>처음 화면으로 돌아가 다시 진행해주세요</strong>
            </>
        );
    };
    return (
        <div className="app ">
            <div className="border-padding">
                <TitleComponent subtitle={subtitleDiv()} />

                <div className="center-button">
                    <Banner
                        bannerTitle={'PAYMENT FAIL'}
                        contentDiv={paymentFailDiv()}
                    />
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default PaymentFail;
