/* eslint-disable jsx-a11y/img-redundant-alt */
import '../assets/styles/modal.css';
import '../App.css';

// import useEffect
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

function ResultImageModal(props) {
    const modalImage = props.modalImage;
    const setShowModal = props.setShowModal;
    const modalImageIndex = props.modalImageIndex;
    const userId = props.userId;
    const secretKey = props.secretKey;
    // imgRef
    const imgRef = useRef(null);

    let navigate = useNavigate();

    const routeChange = (isInternal, path, e) => {
        e.preventDefault();
        if (isInternal) {
            let newPath = path;
            navigate(newPath);
        } else {
            window.open(path);
        }
    };

    const changeShowModal = () => {
        setShowModal(false);
    };

    async function downloadImage(e) {
        // // just open link...
        fetch(modalImage, {
            method: 'GET',
        })
            .then((res) => {
                return res.blob();
            })
            .then((blob) => {
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');

                link.href = url;
                link.download = 'raymong_result.jpg';

                link.click();
            })
            .catch((e) => {
                console.error(e);
            });
    }

    return (
        <div className="crop-modal">
            <div
                className="modal-background"
                onClick={() => {
                    changeShowModal();
                }}
            ></div>
            <div className="modal-banner">
                <div className="banner-title">
                    <p>IMAGE VIEW</p>
                </div>
                <div className="banner-content">
                    <div className="modal-body">
                        <img
                            ref={imgRef}
                            className="modal-result-image"
                            src={modalImage}
                            alt="result-image"
                            loading="lazy"
                        />
                    </div>
                    <div className="mobile-download-guide">
                        사진을 꾹 눌러 다운로드하세요
                    </div>
                    <div className="pc-download-guide">
                        이미지를 우클릭 한 후 이미지를 다른 이름으로 저장하세요
                    </div>
                    <div>
                        <div className="modal-buttons">
                            <div
                                className="white-button"
                                onClick={(e) => {
                                    routeChange(
                                        true,
                                        `/purchase-canvas?userId=${userId}&secretKey=${secretKey}&imageId=${modalImageIndex}`,
                                        e
                                    );
                                }}
                            >
                                캔버스로
                                <br />
                                소장하기
                            </div>
                            {/* <div
                                className="white-button desktop-download-button"
                                onClick={(e) => {
                                    downloadImage(e);
                                }}
                            >
                                다운로드
                            </div> */}
                            <div
                                className="white-button"
                                onClick={() => {
                                    changeShowModal();
                                }}
                            >
                                닫기
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResultImageModal;
