import '../App.css';
import { useNavigate } from 'react-router-dom';

function TitleComponent(props) {
    const subtitle = props.subtitle;

    const navigate = useNavigate();

    const routeChange = (isInternal, path, e) => {
        e.preventDefault();
        if (isInternal) {
            let newPath = path;
            navigate(newPath);
        } else {
            window.open(path);
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <div
                className="website-title"
                onClick={(e) => {
                    routeChange(true, '/', e);
                }}
            >
                RAYMONG
            </div>
            <div className="page-subtitle">{subtitle}</div>
        </div>
    );
}

export default TitleComponent;
