import { loadTossPayments } from '@tosspayments/payment-sdk';
import axios from 'axios';
import '../App.css';
import Banner from '../components/Banner';
import Loading from '../components/Loading';
import { useState, useEffect } from 'react';
import TitleComponent from '../components/TitleComponent';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

function Order() {
    const [promoCode, setPromoCode] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [paidStatus, setPaidStatus] = useState(false);
    const userId = searchParams.get('userId');
    const [discountAmount, setDiscountAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [price, setPrice] = useState(3900);
    const [isAgree, setIsAgree] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({
        cStatus: '',
        createDatetime: '',
        email: '',
        endDatetime: '',
        kind: '',
        name: '',
        paid: false,
        price: '',
        receipt: '',
        startDatetime: '',
    });

    const successUrl = process.env.REACT_APP_ADDRESS + '/payment-success';
    const failUrl = process.env.REACT_APP_ADDRESS + '/payment-fail';

    const navigate = useNavigate();

    const checkPaidStatus = () => {
        setIsLoading(true);

        let url = `${process.env.REACT_APP_API_ADDRESS}/users/${userId}`;

        axios
            .get(url)
            .then((res) => {
                setUserData(res.data);
                if (
                    parseInt(res.data.cStatus) === 0 ||
                    parseInt(res.data.cStatus) === 4
                ) {
                    setPaidStatus(false);
                    setIsLoading(false);
                } else {
                    setPaidStatus(true);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error.response.data.message);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        checkPaidStatus();
    }, []);

    useEffect(() => {
        setTotalAmount(price - discountAmount);
    }, [price, totalAmount, discountAmount]);

    const changePromoCode = (e) => {
        setPromoCode(e.target.value);
    };
    const changeIsAgree = (e) => {
        setIsAgree(e.target.checked);
    };

    const validatePromoCode = (e) => {
        e.preventDefault();

        if (promoCode != '') {
            let url = `${process.env.REACT_APP_API_ADDRESS}/check_promo_code`;

            let data = {
                promo_code: promoCode,
                userId: userId,
            };
            let config = { 'Content-Type': 'application/json' };
            setIsLoading(true);
            axios
                .post(url, data, config)
                .then((res) => {
                    setIsLoading(false);
                    if (res.data.discountAmount) {
                        alert('할인코드가 적용되었습니다.');
                        setDiscountAmount(res.data.discountAmount);

                        let url = `${process.env.REACT_APP_API_ADDRESS}/apply_promo_code`;
                        let data = {
                            userId: userId,
                            promoCode: promoCode,
                        };
                        let config = { 'Content-Type': 'application/json' };

                        axios
                            .post(url, data, config)
                            .then((res) => {})
                            .catch((error) => {
                                console.log(error.response.data.message);
                            });
                    } else {
                        alert('할인코드가 유효하지 않습니다.');
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.response.data.message === 'NOT_AVAILABLE') {
                        alert('이미 사용된 코드입니다.');
                    }

                    if (error.response.data.message === 'NOT_EXIST') {
                        alert('존재하지 않는 코드입니다.');
                    }
                });
        }
    };

    const processCheckout = (e) => {
        e.preventDefault();

        let clientKey = 'live_ck_5GePWvyJnrKZpRnODlEVgLzN97Eo'; // 토스 클라이언트 키

        if (totalAmount === 0) {
            // redirect to paymentsuccess url
            navigate(
                `/payment-success?orderId=${userId}&paymentKey=${''}&amount=${totalAmount}`
            );
        } else {
            loadTossPayments(clientKey)
                .then((tossPayments) => {
                    tossPayments.requestPayment('카드', {
                        // 결제 수단 파라미터
                        // 결제 정보 파라미터
                        amount: totalAmount,
                        orderId: userId,
                        orderName: `레이몽`,
                        successUrl: successUrl,
                        failUrl: failUrl,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    if (error.code === 'USER_CANCEL') {
                        alert('결제를 취소하였습니다.');
                    }
                });
        }
    };

    const checkoutDiv = () => {
        return (
            <>
                <div>
                    {!paidStatus ? (
                        <div>
                            <div
                                className="input-field"
                                style={{ margin: '20px 0' }}
                            >
                                <div className="table-title">
                                    <label>PRODUCT INFO</label> <span></span>
                                </div>

                                <div className="justify-content-space-between">
                                    <div className="info-title">이름</div>
                                    <div className="info-title">
                                        {userData.name}
                                    </div>
                                </div>
                                <div className="justify-content-space-between">
                                    <div className="info-title">종류</div>
                                    <div className="info-title">
                                        {userData.kind.includes(' dog') ||
                                        userData.kind === 'dog'
                                            ? '강아지'
                                            : userData.kind.includes(' cat') ||
                                              userData.kind === 'cat'
                                            ? '고양이'
                                            : '오류'}
                                    </div>
                                </div>
                                <div className="justify-content-space-between">
                                    <div className="info-title">이메일</div>
                                    <div className="info-title">
                                        {userData.email}
                                    </div>
                                </div>
                                <div>&nbsp;</div>

                                <div className="input-field">
                                    <div className="table-title">
                                        <label>PROMO CODE</label>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <input
                                            className="custom-input-text"
                                            type="text"
                                            value={promoCode}
                                            placeholder="할인코드가 있다면 입력해주세요."
                                            onChange={(e) => {
                                                changePromoCode(e);
                                            }}
                                        />
                                        <div>
                                            <button
                                                style={{
                                                    minWidth: '80px',
                                                    marginLeft: '15px',
                                                    fontSize: '15px',
                                                    marginTop: '5px',
                                                    padding: '7px 3px',
                                                }}
                                                onClick={(e) => {
                                                    validatePromoCode(e);
                                                }}
                                            >
                                                확인
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>&nbsp;</div>
                            <div className="input-field">
                                <div className="table-title">
                                    <label>Payment info</label>
                                </div>
                                <div className="justify-content-space-between">
                                    <div className="info-title">PRICE</div>
                                    <div className="info-info">{price}원</div>
                                </div>

                                <div className="justify-content-space-between">
                                    <div className="info-title">DISCOUNT</div>
                                    <div className="info-info">
                                        {discountAmount}원
                                    </div>
                                </div>
                                <div className="justify-content-space-between">
                                    <div className="orange-info-title">
                                        TOTAL
                                    </div>
                                    <div className="orange-info-info">
                                        {totalAmount}원
                                    </div>
                                </div>
                            </div>
                            <div className="input-field">
                                <input
                                    id="agree-checkbox"
                                    type="checkbox"
                                    value={isAgree}
                                    onChange={(e) => {
                                        changeIsAgree(e);
                                    }}
                                />
                                <label
                                    className="checkbox-label"
                                    htmlFor="agree-checkbox"
                                >
                                    환불 정책을 이해하였으며 이에 동의합니다
                                </label>
                            </div>
                            <div className="center-button">
                                <button
                                    disabled={!isAgree}
                                    onClick={(e) => {
                                        processCheckout(e);
                                    }}
                                    style={{ fontSize: '20px' }}
                                >
                                    CHECKOUT
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                fontSize: '16px',
                                fontFamily: 'Suite-regular',
                            }}
                        >
                            <div>이미 결제를 완료하였습니다</div>
                            <div>
                                결제 전 입력하신 이메일로 진행 상태 확인 링크를
                                발송해드렸습니다{' '}
                            </div>
                            <div>이메일을 확인해주세요 :)</div>
                        </div>
                    )}
                </div>
            </>
        );
    };

    const refundPolicyDiv = () => {
        return (
            <>
                <div className="colored-bold" style={{ fontSize: '20px' }}>
                    환불 규정 안내
                </div>
                <div>
                    <ul
                        style={{
                            lineHeight: '180%',
                        }}
                    >
                        <li>
                            결제 완료 후 즉시 AI서버가 가동되어 환불이
                            불가능합니다.
                        </li>
                        <li>
                            생성에 오류가 발생하거나 실패할 경우 자동으로 환불이
                            되며 자동 환불이 되지 않았을 경우 결제시 작성하셨던
                            이메일을 통해{' '}
                            <b style={{ fontFamily: 'Suite-bold' }}>
                                dobby@spade6.dev
                            </b>
                            로 문의해주세요. <br></br>
                            오류 확인 후 환불해드리겠습니다.
                        </li>
                    </ul>
                </div>
            </>
        );
    };

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="app">
                    <div className="border-padding">
                        <TitleComponent
                            subtitle={
                                '이미지를 완성하기 위해 결제를 진행해주세요'
                            }
                        />
                        <div className="page-content">
                            <Banner
                                bannerTitle={'CHECKOUT'}
                                contentDiv={checkoutDiv()}
                            />
                            <Banner
                                bannerTitle={'REFUND POLICY'}
                                contentDiv={refundPolicyDiv()}
                            />
                        </div>
                    </div>

                    <Footer />
                </div>
            )}
        </div>
    );
}

export default Order;
