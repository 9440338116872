import '../assets/styles/Footer.css';
import '../App.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function Footer() {
    let navigate = useNavigate();

    let info = {
        companyName: '스페이드6',
        ceo: '장해지',
        companyAddress: '대전광역시 서구 월평동로26번길 40 지하 1층',
        phoneNumber: '01029103384',
        companyNumber: '742-01-02623',
        networkBusinessNumber: '2022-대전서구-1904',
        emailAddress: 'dobby@spade6.dev',
    };

    function FooterInfo() {
        return (
            <div className="footer-container ">
                <div className="plain-text" style={{ textAlign: 'left' }}>
                    <div>상호명: {info.companyName}</div>
                    <div>대표자명: {info.ceo}</div>
                    <div>주소: {info.companyAddress}</div>
                    <div>전화번호: {info.phoneNumber}</div>
                    <div>사업자등록번호: {info.companyNumber}</div>
                    <div>통신판매업 신고: {info.networkBusinessNumber}</div>
                    <div>이메일: {info.emailAddress}</div>
                </div>
            </div>
        );
    }

    const routeChange = (isInternal, path, e) => {
        e.preventDefault();
        if (isInternal) {
            let newPath = path;
            navigate(newPath);
        } else {
            window.open(path);
        }
    };

    return (
        <div className="">
            <FooterInfo />
        </div>
    );
}

export default Footer;
