import '../App.css';
// import useEffect
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SendImages(props) {
    const croppedImages = props.croppedImages;
    const changeInputImageIndex = props.changeInputImageIndex;
    let navigate = useNavigate();

    const [title, setTitle] = useState('');
    const [email, setEmail] = useState('');
    const [kind, setKind] = useState('');
    const [isAgree, setIsAgree] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [breeds, setBreeds] = useState('');
    const [breed, setBreed] = useState('');

    const catBreeds = require('../assets/data/breeds.json').catBreeds;
    const dogBreeds = require('../assets/data/breeds.json').dogBreeds;

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const changeEmail = (e) => {
        if (!isValidEmail(e.target.value)) {
            setEmailError('올바른 형식의 이메일을 입력해주세요');
        } else {
            setEmailError(null);
        }

        setEmail(e.target.value);
    };

    const changeTitle = (e) => {
        setTitle(e.target.value);
    };

    const changeKind = (kind, e) => {
        setKind(kind);

        // change the background color of the selected kind
        let kinds = document.getElementsByClassName('kind');
        for (let i = 0; i < kinds.length; i++) {
            kinds[i].style.backgroundColor = 'white';
        }
        e.target.style.backgroundColor = '#F2D7C0';

        if (kind === 'dog') {
            setBreeds(dogBreeds);
            setBreed(dogBreeds[0].eng);
        } else if (kind === 'cat') {
            setBreeds(catBreeds);
            setBreed(catBreeds[0].eng);
        }
    };

    const changeBreed = (e) => {
        setBreed(e.target.value);
    };

    const changeIsAgree = (e) => {
        setIsAgree(e.target.checked);
    };

    const saveImages = (e) => {
        e.preventDefault();

        // check everything is filled
        if (title === '' || email === '' || kind === '' || !isAgree) {
            alert('모든 항목을 입력해주세요');
            return;
        } else {
            // remove 'data:image/jpeg;base64,' from the beginning of the string
            for (let i = 0; i < croppedImages.length; i++) {
                croppedImages[i] = croppedImages[i].replace(
                    /^data:image\/[a-z]+;base64,/,
                    ''
                );
            }

            let url = `${process.env.REACT_APP_API_ADDRESS}/users`;
            let data = {
                name: title,
                email: email,
                kind: breed + ' ' + kind,
                images: croppedImages,
            };

            let config = { 'Content-Type': 'application/json' };

            axios
                .post(url, data, config)
                .then((res) => {
                    if (res.data.userId) {
                        let newPath = '/order?userId=' + res.data.userId;
                        navigate(newPath);
                    }
                })
                .catch((error) => {
                    console.log(error.response.data.message);
                });
        }
    };

    useEffect(() => {
        if (
            title === '' ||
            email === '' ||
            kind === '' ||
            kind === 'none' ||
            !isAgree ||
            !isValidEmail(email)
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [title, email, kind, isAgree]);

    return (
        <div style={{ fontFamily: 'Suite-regular' }}>
            <div className="input-field">
                <div className="input-info">
                    <label>타이틀</label>{' '}
                    <span>- 생성하려는 대상의 이름을 정해주세요</span>
                </div>
                <input
                    className="custom-input-text"
                    type="text"
                    value={title}
                    onChange={(e) => {
                        changeTitle(e);
                    }}
                />
            </div>

            <div className="input-field">
                <div className="input-info">
                    <label>이메일</label>{' '}
                    <span>- 모든 진행 상황은 이메일로 안내해드립니다</span>
                </div>

                <input
                    className="custom-input-text"
                    type="email"
                    value={email}
                    onChange={(e) => {
                        changeEmail(e);
                    }}
                />
                <div
                    style={{
                        height: '12px',
                        fontWeight: 'bold',
                        color: '#F24646',
                        margin: '3px 8px',
                        fontSize: '12px',
                    }}
                >
                    {emailError}
                </div>
            </div>

            <div className="input-field">
                <div className="input-info">
                    <label>대상</label>{' '}
                    <span>- 동물의 종류를 선택해주세요</span>
                </div>
                <div className="kind-buttons" style={{ marginTop: '10px' }}>
                    <div
                        className="white-button kind"
                        onClick={(e) => {
                            changeKind('dog', e);
                        }}
                    >
                        강아지
                    </div>
                    <div
                        style={{ marginLeft: '10px' }}
                        className="white-button kind"
                        onClick={(e) => {
                            changeKind('cat', e);
                        }}
                    >
                        고양이
                    </div>
                </div>
            </div>

            <div className="input-field">
                <div className="input-info">
                    <label>동물 품종</label>{' '}
                    <span>- 반려동물이 어떤 동물인지 알려주세요</span>
                </div>
                <select
                    onChange={(e) => changeBreed(e)}
                    id="breed"
                    name="breeds"
                >
                    {breeds.length > 0
                        ? breeds.map((breed, index) => {
                              return (
                                  <option key={index} value={breed.eng}>
                                      {breed.kor}
                                  </option>
                              );
                          })
                        : null}
                </select>
            </div>
            <div className="input-field">
                <div className="input-info">
                    <label>사진</label>{' '}
                    <span>
                        - 사진을 클릭하여 대상 중심으로 크롭할 위치를 수정하세요
                    </span>
                </div>
                <div className="upload-image-preview">
                    {' '}
                    {croppedImages.map((file, index) => {
                        return (
                            <img
                                onClick={() => changeInputImageIndex(index)}
                                key={index}
                                className="preview-image"
                                src={file}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="input-field">
                <input
                    id="agree-checkbox"
                    type="checkbox"
                    value={isAgree}
                    onChange={(e) => {
                        changeIsAgree(e);
                    }}
                />
                <label className="checkbox-label" htmlFor="agree-checkbox">
                    정보성(광고성 X) 이메일 수신에 동의합니다
                </label>
            </div>
            <div className="center-button">
                <button
                    disabled={disabled}
                    onClick={(e) => {
                        saveImages(e);
                    }}
                >
                    확인
                </button>
            </div>
        </div>
    );
}

export default SendImages;
