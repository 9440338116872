import '../assets/styles/modal.css';
import Banner from './Banner';
// import useEffect
import { useEffect, useState, useRef } from 'react';

function ImageCropModal(props) {
    const originalImage = props.originalImage;
    const croppedImage = props.croppedImage;
    const inputImageIndex = props.inputImageIndex;
    const changeCroppedImage = props.changeCroppedImage;
    const setShowModal = props.setShowModal;
    const currentXs = props.currentXs;
    const currentYs = props.currentYs;
    const setCurrentXs = props.setCurrentXs;
    const setCurrentYs = props.setCurrentYs;
    const deleteImageFromArray = props.deleteImageFromArray;

    // make canvas to crop the image
    const [canvas, setCanvas] = useState(null);
    const [ctx, setCtx] = useState(null);
    const [image, setImage] = useState(null);
    const [currentX, _setCurrentX] = useState(currentXs[inputImageIndex]);
    const [currentY, _setCurrentY] = useState(currentYs[inputImageIndex]);
    const [startX, _setStartX] = useState(0);
    const [startY, _setStartY] = useState(0);
    const [cropMode, setCropMode] = useState('hor');
    const [baseLength, setBaseLength] = useState(
        window.innerWidth >= 768 ? 512 : 256
    );

    // ref current value
    const currentXRef = useRef(currentX);
    const currentYRef = useRef(currentY);
    const startXRef = useRef(startX);
    const startYRef = useRef(startY);
    const setCurrentX = (data) => {
        currentXRef.current = data;
        _setCurrentX(data);
    };
    const setCurrentY = (data) => {
        currentYRef.current = data;
        _setCurrentY(data);
    };
    const setStartX = (data) => {
        startXRef.current = data;
        _setStartX(data);
    };
    const setStartY = (data) => {
        startYRef.current = data;
        _setStartY(data);
    };

    useEffect(() => {
        // set canvas size to original image size
        const _canvas = document.getElementById('canvas');
        const _ctx = _canvas.getContext('2d');
        const _image = new Image();
        let _cropMode = 'hor';
        _image.onload = function () {
            let base_length = baseLength;
            let mapped_width = base_length;
            let mapped_height = base_length;
            // if image size is smaller than canvas size, set base size to image size
            if (_image.width < base_length || _image.height < base_length) {
                base_length =
                    _image.width > _image.height ? _image.height : _image.width;
            }
            if (_image.width > _image.height) {
                mapped_width = base_length;
                mapped_height = (_image.height / _image.width) * base_length;
                _cropMode = 'hor';
            } else {
                mapped_width = (_image.width / _image.height) * base_length;
                mapped_height = base_length;
                _cropMode = 'ver';
            }

            _canvas.width = mapped_width;
            _canvas.height = mapped_height;

            _ctx.drawImage(
                _image,
                0,
                0,
                _image.width,
                _image.height,
                0,
                0,
                mapped_width,
                mapped_height
            );
            // if currentX and currentY is not gonna change, set currentX and currentY to 0.1
            if (
                currentXs[inputImageIndex] === 0 &&
                currentYs[inputImageIndex] === 0
            ) {
                setCurrentX(0.1);
                setCurrentY(0.1);
            } else {
                setCurrentX((currentX / _image.width) * mapped_width);
                setCurrentY((currentY / _image.height) * mapped_height);
            }

            setBaseLength(base_length);
            setCropMode(_cropMode);

            // make rect inside canvas which will be cropped and movable
            const rect = {
                x: 0,
                y: 0,
                width: base_length,
                height: base_length,
                startX: 0,
                startY: 0,
                currentXatStart: 0,
                currentYatStart: 0,
                isDown: false,
            };
            _canvas.addEventListener('mousedown', (e) => {
                rect.isDown = true;
                rect.startX = e.offsetX;
                rect.startY = e.offsetY;
                setStartX(currentXRef.current);
                setStartY(currentYRef.current);
            });
            _canvas.addEventListener('touchstart', (e) => {
                e.preventDefault();
                let touch = e.touches[0];
                let mouseEvent = new MouseEvent('mousedown', {
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                });
                _canvas.dispatchEvent(mouseEvent);
            });

            _canvas.addEventListener('mouseup', (e) => {
                rect.isDown = false;
            });
            _canvas.addEventListener('touchend', (e) => {
                e.preventDefault();
                let mouseEvent = new MouseEvent('mouseup', {});
                _canvas.dispatchEvent(mouseEvent);
            });

            _canvas.addEventListener('mousemove', (e) => {
                if (rect.isDown) {
                    if (_cropMode === 'hor') {
                        if (startXRef.current - rect.startX + e.offsetX < 0) {
                            rect.x = 0;
                        } else if (
                            _canvas.width <
                            startXRef.current +
                                _canvas.height -
                                rect.startX +
                                e.offsetX
                        ) {
                            rect.x = _canvas.width - _canvas.height;
                        } else {
                            rect.x =
                                startXRef.current - rect.startX + e.offsetX;
                        }
                        setCurrentX(rect.x);
                    } else {
                        if (startYRef.current - rect.startY + e.offsetY < 0) {
                            rect.y = 0;
                        } else if (
                            _canvas.height <
                            startYRef.current +
                                _canvas.width -
                                rect.startY +
                                e.offsetY
                        ) {
                            rect.y = _canvas.height - _canvas.width;
                        } else {
                            rect.y =
                                startYRef.current - rect.startY + e.offsetY;
                        }
                        setCurrentY(rect.y);
                    }
                }
            });
            _canvas.addEventListener('touchmove', (e) => {
                e.preventDefault();
                let touch = e.touches[0];
                let mouseEvent = new MouseEvent('mousemove', {
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                });
                _canvas.dispatchEvent(mouseEvent);
            });
            drawTransparentRect();
        };
        _image.src = originalImage;
        setCanvas(_canvas);
        setCtx(_ctx);
        setImage(_image);
    }, []);

    useEffect(() => {
        drawTransparentRect();
    }, [currentX, currentY, ctx]);

    const drawTransparentRect = () => {
        if (ctx === null || canvas === null) return;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            canvas.width,
            canvas.height
        );
        ctx.save();
        ctx.fillStyle = 'rgba(0, 0, 0, 0.6)';

        if (cropMode === 'hor') {
            ctx.fillRect(0, 0, currentX, canvas.height);
            ctx.fillRect(
                currentX + canvas.height,
                0,
                canvas.width - canvas.height - currentX,
                canvas.height
            );
        } else {
            ctx.fillRect(0, 0, canvas.width, currentY);
            ctx.fillRect(
                0,
                currentY + canvas.width,
                canvas.width,
                canvas.height - canvas.width - currentY
            );
        }
        ctx.restore();
    };

    const cropImage = (e) => {
        if (e) e.preventDefault();
        // create virtual canvas to crop original image
        const virtualCanvas = document.createElement('canvas');
        const virtualCtx = virtualCanvas.getContext('2d');
        virtualCanvas.width = 1024;
        virtualCanvas.height = 1024;
        let sx, sy, sw, sh;

        if (cropMode === 'hor') {
            sx = (currentX * image.width) / canvas.width;
            sy = 0;
            sw = image.height;
            sh = image.height;
            // change currentXs and currentYs with setCurrentXs and setCurrentYs
            let _currentXs = [...currentXs];
            _currentXs[inputImageIndex] = sx;
            setCurrentXs(_currentXs);
        } else {
            sx = 0;
            sy = (currentY * image.height) / canvas.height;
            sw = image.width;
            sh = image.width;
            // change currentXs and currentYs with setCurrentXs and setCurrentYs
            let _currentYs = [...currentYs];
            _currentYs[inputImageIndex] = sy;
            setCurrentYs(_currentYs);
        }
        virtualCtx.drawImage(image, sx, sy, sw, sh, 0, 0, 1024, 1024);
        const croppedImageURI = virtualCanvas.toDataURL('image/jpeg');
        changeCroppedImage(inputImageIndex, croppedImageURI);

        setShowModal(false);
    };

    const cropModalDiv = () => {
        return (
            <>
                {/* <img src={originalImage} alt="originalImage" />
      <img src={croppedImage} alt="croppedImage" /> */}
                <div className="modal-body">
                    <canvas id="canvas" width="100" height="100"></canvas>
                </div>
                <div className="modal-buttons">
                    <div
                        className="white-button"
                        onClick={() => {
                            cropImage(null);
                        }}
                    >
                        확인
                    </div>
                    <div
                        className="white-button"
                        onClick={() => {
                            deleteImageFromArray(inputImageIndex);
                        }}
                    >
                        삭제
                    </div>
                    <div
                        className="white-button"
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        닫기
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className="crop-modal">
            <div
                className="modal-background"
                onClick={() => {
                    setShowModal(false);
                }}
            ></div>
            <Banner contentDiv={cropModalDiv()} bannerTitle={'CROP IMAGE'} />;
        </div>
    );
}

export default ImageCropModal;
