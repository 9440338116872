import Resizer from 'react-image-file-resizer';
import heic2any from 'heic2any';

const readFilesAndExtractURI = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            let image = new Image();
            image.onload = async function () {
                // if (image.width < 512 || image.height < 512) {
                //   alert("사진의 크기가 너무 작습니다.");
                //   reject("사진의 크기가 너무 작습니다.");
                // }
                if (image.width >= image.height) {
                    // 이미지의 가로길이가 세로길이보다 길거나 같을 때 (정방형일때)
                    // get proportion of the image
                    let proportion = image.width / image.height;
                    // resize image
                    Resizer.imageFileResizer(
                        file,
                        1024 * proportion,
                        1024,
                        'JPEG',
                        100,
                        0,
                        (uri) => {
                            resolve(uri);
                        },
                        'base64'
                    );
                } else {
                    // 이미지의 세로길이가 가로길이보다 길때
                    // get proportion of the image
                    let proportion = image.height / image.width;
                    // resize image
                    Resizer.imageFileResizer(
                        file,
                        1024,
                        1024 * proportion,
                        'JPEG',
                        100,
                        0,
                        (uri) => {
                            resolve(uri);
                        },
                        'base64'
                    );
                }
            };
            image.src = reader.result;
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};

const changeFileToJPG = async (file) => {
    return new Promise((resolve, reject) => {
        try {
            if (file.type === 'image/heic') {
                heic2any({
                    blob: file,
                    toType: 'image/jpeg',
                    quality: 1,
                })
                    .then((result) => {
                        let resultFile = new File(
                            [result],
                            file.name.split('.')[0] + '.jpg',
                            {
                                type: 'image/jpeg',
                                lastModified: new Date().getTime(),
                            }
                        );

                        resolve(resultFile);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                resolve(file);
            }
        } catch (error) {
            reject(error);
        }
    });
};

const autoCropImages = (file) => {
    return new Promise((resolve, reject) => {
        // create canvas and draw file, auto crop the image
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        let image = new Image();
        image.onload = function () {
            let base_length = 1024;
            let mapped_width = base_length;
            let mapped_height = base_length;
            canvas.width = base_length;
            canvas.height = base_length;
            // center the image
            let offsetX = 0;
            let offsetY = 0;
            // if image size is smaller than canvas size, set base size to image size
            if (image.width < base_length || image.height < base_length) {
                base_length =
                    image.width > image.height ? image.height : image.width;
            }
            if (image.width > image.height) {
                offsetX = Math.abs(image.width - base_length) / 2;
            } else {
                offsetY = Math.abs(image.height - base_length) / 2;
            }
            ctx.drawImage(
                image,
                offsetX,
                offsetY,
                base_length,
                base_length,
                0,
                0,
                1024,
                1024
            );
            resolve({
                offsetX,
                offsetY,
                canvas: canvas.toDataURL('image/jpeg'),
            });
        };
        image.src = file;
    });
};

export { readFilesAndExtractURI, changeFileToJPG, autoCropImages };
