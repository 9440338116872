import { loadTossPayments } from '@tosspayments/payment-sdk';
import axios from 'axios';
import '../assets/styles/PurchaseCanvas.css';
import '../App.css';

import Loading from '../components/Loading';
import { useState, useEffect } from 'react';
import TitleComponent from '../components/TitleComponent';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import frameSize from '../assets/images/frame-size.png';
import standingAlone from '../assets/images/standing-alone.webp';
import lightWeight from '../assets/images/light-weight.webp';
import neatDetail from '../assets/images/neat-details.webp';
import exampleCanvas from '../assets/images/canvas-example.jpg';
import optionLocation from '../assets/images/option-location.png';

function PurchaseCanvas(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [chosenImage, setChosenImage] = useState('');
    const userId = searchParams.get('userId');
    const secretKey = searchParams.get('secretKey');
    const modalImageIndex = searchParams.get('imageId');

    const navigate = useNavigate();

    useEffect(() => {
        getResultImages();
    }, [userId, secretKey]);

    const getResultImages = () => {
        setIsLoading(true);
        let url = `${process.env.REACT_APP_API_ADDRESS}/get_images?userId=${userId}&secretKey=${secretKey}`;

        axios
            .get(url)
            .then((res) => {
                setChosenImage(res.data.urls[modalImageIndex]);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response.data.error === 'image is not ready') {
                    alert('이미지가 존재하지 않습니다');
                    navigate(`/status?userId=${userId}&secretKey=${secretKey}`);
                }
                setIsLoading(false);
            });
    };

    const routeChange = (isInternal, path, e) => {
        e.preventDefault();
        if (isInternal) {
            let newPath = path;
            navigate(newPath);
        } else {
            window.open(path);
        }
    };

    const subtitleDiv = () => {
        return <></>;
    };

    const copyOptionsToClipboard = async () => {
        // copy secretkey and image index to clipboard
        const options = `${secretKey}-${modalImageIndex}`;
        await navigator.clipboard.writeText(options);
        alert('필수 옵션이 복사되었습니다');
    };
    const chosenImageDiv = () => {
        return (
            <div>
                <div className="canvas-product-detail">
                    <div className="chosen-image-for-canvas">
                        <img
                            src={chosenImage}
                            alt="result-image"
                            loading="lazy"
                        />
                    </div>
                    <div className="canvas-product-detail-content">
                        <div className="product-name">
                            [자체제작] 레이몽 커스텀 캔버스 액자
                        </div>
                        <div className="product-price">39800원</div>
                        <div className="product-info">
                            <p>
                                마음에 드는 내 반려동물의 그림을 무광 캔버스
                                액자로 소장하세요
                            </p>
                            <p>
                                가벼운 무게와 자립이 가능한 디자인으로 집안
                                곳곳에 세워놓을 수 있어요
                            </p>

                            <p>사이즈: 252mm x 252mm x 40mm</p>
                        </div>
                        <div style={{ marginTop: '30px' }}>
                            <p className="highlighted-text">주문 방법</p>
                        </div>
                        <div className="order-instruction">
                            <div>
                                <div>
                                    1. 아래의 '캔버스 액자 주문하기'를 클릭하여
                                    네이버 스마트 스토어로 이동합니다.
                                </div>
                                <div className="order-buttons">
                                    <div
                                        className="white-button"
                                        onClick={(e) => {
                                            routeChange(
                                                false,
                                                `https://smartstore.naver.com/bincanart/products/9502328359`,
                                                e
                                            );
                                        }}
                                    >
                                        캔버스 액자 주문하기
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: '30px' }}>
                                <div>
                                    2. 필수 옵션에 아래 버튼(필수 옵션
                                    복사하기)을 눌러 옵션을 복사하고 아래 사진에
                                    표시된 칸에 붙여넣기 해 주세요.
                                </div>
                                <div className="order-buttons">
                                    <div
                                        className="white-button"
                                        onClick={() => copyOptionsToClipboard()}
                                    >
                                        필수 옵션 복사하기
                                    </div>

                                    <div style={{ marginTop: '10px' }}>
                                        만약 복사가 되지 않는다면 직접
                                        적어주세요. <br />
                                        <strong
                                            style={{ fontFamily: 'Suite-bold' }}
                                        >
                                            옵션:{' '}
                                            {`${secretKey}-${modalImageIndex}`}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <img
                            style={{
                                width: 'calc(100% - 2px)',
                                marginTop: '30px',
                                border: '1px solid black',
                            }}
                            src={optionLocation}
                            alt="option-location"
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="app ">
                    <div className="border" style={{ maxHeight: 'none' }}>
                        <div className="border-padding">
                            <TitleComponent subtitle={subtitleDiv()} />
                            <div className="">
                                <div>{chosenImageDiv()}</div>
                                <div
                                    style={{
                                        fontFamily: 'Suite-regular',
                                        textAlign: 'center',
                                    }}
                                >
                                    <div className="product-detail-title">
                                        캔버스 액자 상세 정보
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <div style={{ margin: '8px 0' }}>
                                            <p className="highlighted-text">
                                                무광 캔버스를 사용한 캔버스 액자
                                            </p>
                                        </div>
                                        <div style={{ marginBottom: '15px' }}>
                                            직접 제작한 나무틀과 프리미엄 무광
                                            캔버스 용지에 <br />
                                            인쇄하여 더 깔끔한 마감으로 캔버스
                                            액자를 제작합니다.
                                        </div>

                                        <div className="example-canvas">
                                            <img
                                                src={exampleCanvas}
                                                alt="example-canvas"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            margin: '30px 0',
                                        }}
                                    >
                                        <div style={{ marginBottom: '15px' }}>
                                            <p className="highlighted-text">
                                                캔버스 액자 사이즈
                                            </p>
                                        </div>

                                        <div className="example-canvas">
                                            <img
                                                style={{
                                                    width: '100%',
                                                    margin: '0',
                                                }}
                                                src={frameSize}
                                                alt="frame-size"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            marginTop: '50px',
                                        }}
                                    >
                                        <div style={{ marginBottom: '30px' }}>
                                            <p
                                                style={{ fontSize: '22px' }}
                                                className="highlighted-text"
                                            >
                                                레이몽 캔버스 액자가 가진 장점
                                                3가지
                                            </p>
                                        </div>

                                        <div style={{ marginBottom: '30px' }}>
                                            <div
                                                style={{ marginBottom: '5px' }}
                                            >
                                                1. 자립이 가능하여 쉽게 집안
                                                곳곳에 세워놓을 수 있어요!
                                            </div>
                                            <div className="example-canvas">
                                                <img
                                                    src={standingAlone}
                                                    alt="standing-alone"
                                                />
                                            </div>
                                        </div>

                                        <div style={{ marginBottom: '30px' }}>
                                            <div
                                                style={{ marginBottom: '5px' }}
                                            >
                                                2. 무겁지 않아 꼭꼬핀 등을
                                                활용하여 벽에 걸 수 있어요!
                                            </div>
                                            <div className="example-canvas">
                                                <img
                                                    src={lightWeight}
                                                    alt="light-weight"
                                                />
                                            </div>
                                        </div>
                                        <div style={{ marginBottom: '30px' }}>
                                            <div
                                                style={{ marginBottom: '5px' }}
                                            >
                                                3. 깔끔한 마감과 탱탱한 텐션,
                                                작은 디테일까지 챙겼어요!
                                            </div>
                                            <div className="example-canvas">
                                                <img
                                                    src={neatDetail}
                                                    alt="neat-details"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            margin: '30px 0',
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <p
                                                style={{ fontSize: '22px' }}
                                                className="highlighted-text"
                                            >
                                                제작 과정 안내
                                            </p>
                                        </div>
                                        <div>
                                            주문 접수 후 이메일로 시안을 먼저
                                            보내드립니다. 시안 확인 후 캔버스
                                            액자 제작이 시작됩니다. <br />
                                            시안 확인 후에는 주문 취소가
                                            불가능한 점 참고해 주세요.
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            margin: '30px 0',
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <p
                                                style={{ fontSize: '22px' }}
                                                className="highlighted-text"
                                            >
                                                배송 및 교환/환불 안내
                                            </p>
                                        </div>
                                        <div style={{ lineHeight: '150%' }}>
                                            1. 주문 제작 상품이기 때문에 최초
                                            주문일부터 배송까지 최대 7일이
                                            소요될 수 있습니다. <br />
                                            2. 주문 제작 상품이기 때문에 상품
                                            수령 후 단순 변심으로 인한
                                            교환/환불은 불가능합니다.
                                            <br />
                                            3. 단순 변심 외에 상품의 하자로 인한
                                            교환/환불은 가능합니다. 단, 다음의
                                            경우에 한해 교환/환불이 가능합니다.
                                            <br /> - 액자가 배송 중 파손, 훼손,
                                            손상된 경우
                                            <br /> - 액자의 이미지가 주문한
                                            이미지와 다른 경우
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            )}
        </div>
    );
}

export default PurchaseCanvas;
