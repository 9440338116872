import '../App.css';
// import useEffect
import { useEffect, useState } from 'react';
import {
    readFilesAndExtractURI,
    changeFileToJPG,
    autoCropImages,
} from '../function/ImageConvertResizer';
import ImageCropModal from '../components/ImageCropModal';
import SendImages from '../components/SendImages';
import Banner from '../components/Banner';
import Loading from '../components/Loading';
import TitleComponent from '../components/TitleComponent';
import mainImage from '../assets/images/main-page.png';
import yes from '../assets/images/yes.png';
import no from '../assets/images/no.png';
import crop from '../assets/images/crop.png';
import Footer from '../components/Footer';

function Main() {
    const [photos, setPhotos] = useState([]); // original images
    const [croppedImages, setCroppedImages] = useState([]); // cropped images
    const [currentXs, setCurrentXs] = useState([]); // cropped images
    const [currentYs, setCurrentYs] = useState([]); // cropped images
    const [isLoading, setIsLoading] = useState(false);
    const [inputImage, setInputImage] = useState('');
    const [inputImageIndex, setInputImageIndex] = useState(0);
    const [state, setState] = useState(0);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onChange = async (e) => {
        let files = Array.from(e.target.files);

        if (files.length > 4 && files.length < 11) {
            setIsLoading(true);

            // image type 변환
            let jpegFiles = await Promise.all(
                files.map((file) => {
                    return changeFileToJPG(file);
                })
            );

            // 올린 사진들 width, height 중 작은 것을 512로 만들어 리사이징
            let resizedImageArray = await Promise.all(
                jpegFiles.map((file) => {
                    return readFilesAndExtractURI(file);
                })
            );

            let croppedArray = await Promise.all(
                resizedImageArray.map(async (file) => {
                    return autoCropImages(file);
                })
            );

            let croppedImageArray = croppedArray.map((r) => {
                return r.canvas;
            });
            let currentXArray = croppedArray.map((r) => {
                return r.offsetX;
            });
            let currentYArray = croppedArray.map((r) => {
                return r.offsetY;
            });

            setPhotos(resizedImageArray);
            setCroppedImages(croppedImageArray);
            setCurrentXs(currentXArray);
            setCurrentYs(currentYArray);
            setIsLoading(false);
            setState(1);
        } else {
            alert('사진은 5~10장을 업로드하세요');
        }
    };

    const changeInputImageIndex = (index) => {
        setInputImageIndex(index);
        changeShowModal();
    };

    const changeShowModal = () => {
        setShowModal(!showModal);
    };

    const changeCroppedImage = (index, croppedImage) => {
        let newCroppedImages = croppedImages;
        newCroppedImages[index] = croppedImage;
        setCroppedImages(newCroppedImages);
    };

    const uploadBannerDiv = () => {
        return (
            <>
                <div>
                    <label htmlFor="upload-photos">
                        <div id="upload-button">+</div>
                    </label>
                    <input
                        id="upload-photos"
                        type="file"
                        multiple
                        onChange={onChange}
                        accept="image/x-png,image/jpeg,image/jpg,image/heic"
                    />
                </div>
            </>
        );
    };

    const croppedImagesDiv = () => {
        return (
            <>
                <div>
                    <SendImages
                        croppedImages={croppedImages}
                        changeInputImageIndex={changeInputImageIndex}
                    />
                </div>
            </>
        );
    };

    const subtitleDiv = () => {
        return (
            <>
                무엇을 만들고 싶나요?
                <br />
                강아지 혹은 고양이의 사진을 5 ~ 10장 업로드하세요
            </>
        );
    };

    const firstInstructionDiv = () => {
        return (
            <>
                <div style={{ fontFamily: 'Suite-regular' }}>
                    <div style={{ fontSize: '18px' }}>
                        같은 강아지 혹은 고양이의 사진을 5~10장 업로드하세요
                    </div>
                    <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                        <ul style={{ lineHeight: '180%' }}>
                            <li>
                                다양한 각도와 환경일수록 AI가 동물을 이해하는데
                                도움이 됩니다
                            </li>
                            <li>
                                동물이 중앙에 잘 위치하고 사물에 가려지지 않는
                                것이 좋아요
                            </li>
                        </ul>
                        <div
                            style={{ margin: '10px 0', paddingBottom: '20px' }}
                            className="instruction-images"
                        >
                            <div className="instruction-image">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        color: '#658354',
                                        fontSize: '20px',
                                    }}
                                >
                                    YES 👍
                                </div>
                                <img src={yes} />
                            </div>
                            <div className="instruction-image">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        color: '#cd071e',
                                        fontSize: '20px',
                                    }}
                                >
                                    NONO 👎
                                </div>
                                <img src={no} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const secondInstructionDiv = () => {
        return (
            <>
                <div style={{ fontFamily: 'Suite-regular' }}>
                    <div style={{ fontSize: '18px' }}>
                        <ol style={{ lineHeight: '180%' }}>
                            <li>생성할 아트 컬렉션의 이름을 정해주세요.</li>
                            <li>
                                실제{' '}
                                <strong style={{ fontFamily: 'Suite-bold' }}>
                                    사용하는 이메일
                                </strong>
                                을 입력해주세요. 아트 컬렉션의 진행상황과 결과를
                                이메일로 안내해드립니다.
                            </li>
                            <li>
                                동물이 강아지인지 고양이인지 선택해주세요. 그
                                외의 동물들은 아직 지원되지 않습니다.
                            </li>
                            <li>
                                동물이 중앙에 위치하지 않은 사진은 클릭하여
                                동물이 중앙에 오도록 수정해주세요.
                            </li>
                        </ol>
                    </div>

                    <div className="instruction-image crop-image">
                        <div
                            style={{
                                fontWeight: 'bold',
                                fontSize: '15px',
                            }}
                        >
                            예시)
                        </div>
                        <img src={crop} />
                    </div>
                </div>
            </>
        );
    };

    const deleteImageFromArray = (index) => {
        let newPhotos = photos;
        let newCroppedImages = croppedImages;
        let newCurrentXs = currentXs;
        let newCurrentYs = currentYs;

        newPhotos.splice(index, 1);
        newCroppedImages.splice(index, 1);
        newCurrentXs.splice(index, 1);
        newCurrentYs.splice(index, 1);

        setPhotos(newPhotos);
        setCroppedImages(newCroppedImages);
        setCurrentXs(newCurrentXs);
        setCurrentYs(newCurrentYs);
        setShowModal(false);

        if (newPhotos.length === 0) {
            setState(0);
        }
    };

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <div className="app ">
                    <div className="border-padding">
                        <TitleComponent subtitle={subtitleDiv()} />
                        <div className="page-content">
                            {state === 0 ? (
                                <Banner
                                    bannerTitle={'UPLOAD IMAGE'}
                                    contentDiv={uploadBannerDiv()}
                                />
                            ) : state === 1 ? (
                                <Banner
                                    bannerTitle={'UPLOAD IMAGE'}
                                    contentDiv={croppedImagesDiv()}
                                />
                            ) : null}

                            {state === 0 ? (
                                <Banner
                                    contentDiv={firstInstructionDiv()}
                                    bannerTitle={'RAYMONG 참고 사항'}
                                />
                            ) : state === 1 ? (
                                <Banner
                                    contentDiv={secondInstructionDiv()}
                                    bannerTitle={'RAYMONG 참고 사항'}
                                />
                            ) : null}
                        </div>

                        {showModal ? (
                            <ImageCropModal
                                originalImage={photos[inputImageIndex]}
                                currentXs={currentXs}
                                currentYs={currentYs}
                                inputImageIndex={inputImageIndex}
                                changeCroppedImage={changeCroppedImage}
                                setShowModal={setShowModal}
                                setCurrentXs={setCurrentXs}
                                setCurrentYs={setCurrentYs}
                                deleteImageFromArray={deleteImageFromArray}
                            />
                        ) : null}
                    </div>

                    <Footer />
                </div>
            )}
        </div>
    );
}

export default Main;
